import React from "react";
import Badge from "react-bootstrap/Badge";

export const formatDate = (date, message, expired = false) => {
  try {
    if (!date) throw new Error();
    let this_date = new Date(date);
    if (expired && new Date().getTime() > this_date.getTime())
      return (
        <Badge pill={true} bg="warning">
          Expired
        </Badge>
      );
    return `${this_date.getFullYear()}/${String(
      this_date.getMonth() + 1
    ).padStart(2, "0")}/${String(this_date.getDate()).padStart(2, "0")}`;
  } catch {
    return (
      <Badge pill={true} bg="info">
        {message}
      </Badge>
    );
  }
};
