import React, { Component } from "react";
import { admin_api } from "../../atoms/api";
import Container from "react-bootstrap/Container";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Webinar as WebTemplate } from "../../organisms/webinar";
import { WebinarRegistrations } from "../../organisms/webinar-registrations";
import { EmailSubscribers } from "../../organisms/email-subscribers";
import { UserQuestions } from "../../organisms/user-questions";
import { AdminGift } from "../../organisms/admin-gifts";
import { Survey } from "../../organisms/survey-data";
import { SchoolFeedback } from "../../organisms/school-feedback";
import { formatDate } from "../../atoms/formatDate";

export class Webinar extends Component {
  constructor() {
    super();
    this.state = {
      getWebinarLoading: true,
      getWebinarError: null,
      getWebregLoading: true,
      getWebregError: null,
      getSubscribersLoading: true,
      getSubscribersError: null,
      getUserQuestionsLoading: true,
      getUserQuestionsError: null,
      getAdminGiftLoading: true,
      getAdminGiftError: null,
      getpLoading: false,
      getpError: null,
      getSurveyLoading: true,
      getSurveyError: null,
      getSchoolFeedbackLoading: true,
      getSchoolFeedbackError: null,
      survey: [],
      programs: [],
      webinars: [],
      registrations: [],
      subscribers: [],
      userQuestions: [],
      adminGifts: [],
      schoolFeedback: [],
      selectedTab: "gifts",
      addWebinar: false,
      addWebinarLoading: false,
      addWebinarSuccess: false,
      addWebinarError: null,
      addAdminGift: false,
      addAdminGiftLoading: false,
      addAdminGiftSuccess: false,
      addAdminGiftError: null,
      editWebinar: false,
      editWebinarId: null,
      editWebinarLoading: false,
      editWebinarSuccess: false,
      editWebinarError: null,
      editUserQuestion: false,
      editUserQuestionId: null,
      editUserQuestionsLoading: false,
      editUserQuestionsError: null,
      editUserQuestionsSuccess: false,
      editAdminGift: false,
      editAdminGiftId: null,
      editAdminGiftsLoading: false,
      editAdminGiftError: null,
      editAdminGiftSuccess: false,
      deleteWebinar: false,
      deleteWebinarId: null,
      deleteWebinarLoading: false,
      deleteWebinarSuccess: false,
      deleteWebinarError: null,
      deleteAdminGift: false,
      deleteAdminGiftId: null,
      deleteAdminGiftLoading: false,
      deleteAdminGiftSuccess: false,
      deleteAdminGiftError: null,
    };
  }

  setSelectedTab = (tab) => {
    this.setState({ selectedTab: tab });
  };

  componentDidMount() {
    admin_api
      .get("/webinar")
      .then((res) => {
        this.setState({
          getWebinarLoading: false,
          webinars: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          getWebinarLoading: false,
          getWebinarError:
            err?.response?.data?.message || "Error getting webinars",
        });
      });
    admin_api
      .get("/webinar-registration")
      .then((res) => {
        this.setState({ getWebregLoading: false, registrations: res.data });
      })
      .catch((err) => {
        this.setState({
          getWebregLoading: false,
          getWebregError:
            err?.response?.data?.message || "Error getting registrations",
        });
      });
    admin_api
      .get("/email-subscribers/distinct")
      .then((res) => {
        this.setState({
          getSubscribersLoading: false,
          getSubscribersError: null,
          subscribers: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          getSubscribersLoading: false,
          getSubscribersError:
            err?.response?.data?.message || "Error getting subscribers list",
        });
      });
    admin_api
      .get("/user-questions")
      .then((res) => {
        this.setState({
          getUserQuestionsLoading: false,
          userQuestions: res.data,
          getUserQuestionsError: null,
        });
      })
      .catch((err) => {
        this.setState({
          getUserQuestionsLoading: false,
          getUserQuestionsError:
            err?.response?.data?.message || "Error get user questions",
        });
      });
    admin_api
      .get("admin-gift")
      .then((res) => {
        this.setState({
          getAdminGiftLoading: false,
          adminGifts: res.data,
          getAdminGiftError: null,
        });
      })
      .catch((err) => {
        this.setState({
          getAdminGiftError:
            err?.response?.data?.message || "Error getting admin gifts",
          getAdminGiftLoading: false,
        });
      });
    admin_api
      .get("/programs")
      .then((res) => {
        this.setState({
          getpLoading: false,
          getpError: null,
          programs: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          getpLoading: false,
          getpError:
            err?.response?.data?.message || "Error fetching program list",
        });
      });
    admin_api
      .get("/survey")
      .then((res) => {
        this.setState({
          getSurveyError: null,
          getSurveyLoading: false,
          survey: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          getSurveyError:
            err?.response?.data?.message || "Error getting submitted surveys",
          getSurveyLoading: false,
        });
      });
    admin_api
      .get("school/all/feedback")
      .then((res) => res.data)
      .then((res) => {
        this.setState({
          getSchoolFeedbackError: null,
          getSchoolFeedbackLoading: false,
          schoolFeedback: res,
        });
      })
      .catch((err) => {
        this.setState({
          getSchoolFeedbackError:
            err?.response?.data?.message || "Error getting school feedback",
          getSchoolFeedbackLoading: false,
        });
      });
  }

  closeAddSuccess = () => this.setState({ addWebinarSuccess: false });
  closeAddAdminGiftSuccess = () =>
    this.setState({ addAdminGiftSuccess: false });
  closeEditSuccess = () => this.setState({ editWebinarSuccess: false });
  closeEditAdminGiftSuccess = () =>
    this.setState({ editAdminGiftSuccess: false });
  closeEditUserQuestionSuccess = () =>
    this.setState({ editUserQuestionsSuccess: false });
  closeDeleteSuccess = () => this.setState({ deleteWebinarSuccess: false });
  closeDeleteAdminGiftSuccess = () =>
    this.setState({ deleteAdminGiftSuccess: false });

  dismissAddError = () => this.setState({ addWebinarError: null });
  dismissAddAdminGiftError = () => this.setState({ addAdminGiftError: null });
  dismissGetError = () => this.setState({ getWebinarError: null });
  dismissGetRegError = () => this.setState({ getWebregError: null });
  dismissGetUserQuestionsError = () =>
    this.setState({ getUserQuestionsError: null });
  dismissGetAdminGiftError = () => this.setState({ getAdminGiftError: null });
  dismissSubscribersError = () => this.setState({ getSubscribersError: null });
  dismissEditError = () => this.setState({ editWebinarError: null });
  dismissEditUserQuestionsError = () =>
    this.setState({ editUserQuestionsError: null });
  dismissEditAdminGiftError = () => this.setState({ editAdminGiftError: null });
  dismissDeleteError = () => this.setState({ deleteWebinarError: null });
  dismissDeleteAdminGiftError = () =>
    this.setState({ deleteAdminGiftError: null });
  dismissGetProgramError = () => this.setState({ getpError: null });
  dismissGetSurveyError = () => this.setState({ getSurveyError: null });
  dismissGetSchoolFeedbackError = () =>
    this.setState({ getSchoolFeedbackError: null });

  handleAddWebinar = () => this.setState({ addWebinar: true });
  handleAddWebinarClose = () => this.setState({ addWebinar: false });
  handleAddAdminGift = () => this.setState({ addAdminGift: true });
  handleAddAdminGiftClose = () => this.setState({ addAdminGift: false });
  handleEditWebinar = (webinarId) =>
    this.setState({ editWebinar: true, editWebinarId: webinarId });
  handleEditWebinarClose = () =>
    this.setState({ editWebinar: false, editWebinarId: null });
  handleEditAdminGift = (giftId) =>
    this.setState({ editAdminGiftId: giftId, editAdminGift: true });
  handleEditAdminGiftClose = () =>
    this.setState({ editAdminGift: false, editAdminGiftId: null });
  handleDeleteWebinarOpen = (webinarId) =>
    this.setState({ deleteWebinar: true, deleteWebinarId: webinarId });
  handleDeleteWebinarClose = () =>
    this.setState({ deleteWebinar: false, deleteWebinarId: null });
  handleDeleteAdminGiftOpen = (giftId) =>
    this.setState({ deleteAdminGift: true, deleteAdminGiftId: giftId });
  handleDeleteAdminGiftClose = () =>
    this.setState({ deleteAdminGift: false, deleteAdminGiftId: null });
  handleEditUserQuestion = (questionId) =>
    this.setState({ editUserQuestionId: questionId, editUserQuestion: true });
  handleEditUserQuestionClose = () =>
    this.setState({ editUserQuestion: false, editUserQuestionId: null });

  handleDeleteWebinar = () => {
    this.setState({ deleteWebinar: false, deleteWebinarLoading: true });
    admin_api
      .delete(`/webinar/${this.state.deleteWebinarId}`)
      .then(() => {
        let webinars = this.state.webinars.filter(
          (w) => w["id"] !== this.state.deleteWebinarId
        );
        this.setState({
          deleteWebinarId: null,
          deleteWebinarLoading: false,
          deleteWebinarSuccess: true,
          webinars,
        });
      })
      .catch((err) => {
        this.setState({
          deleteWebinarId: null,
          deleteWebinarLoading: false,
          deleteWebinarError: err?.response?.data?.message || "Error",
        });
      });
  };

  handleDeleteAdminGift = () => {
    this.setState({ deleteAdminGift: false, deleteAdminGiftLoading: true });
    admin_api
      .delete(`/admin-gift/${this.state.deleteAdminGiftId}`)
      .then(() => {
        let gifts = this.state.adminGifts.filter(
          (g) => g["gift_code"] !== this.state.deleteAdminGiftId
        );
        this.setState({
          deleteAdminGiftId: null,
          deleteAdminGiftLoading: false,
          deleteAdminGiftSuccess: true,
          adminGifts: gifts,
        });
      })
      .catch((err) => {
        this.setState({
          deleteAdminGiftId: null,
          deleteAdminGiftLoading: false,
          deleteAdminGiftError: err?.response?.data?.message || "Error",
        });
      });
  };

  addWebinarSubmit = (values) => {
    this.setState({ addWebinarLoading: true });
    admin_api
      .post("/webinar", values)
      .then((res) => {
        let webinars = this.state.webinars;
        if (res.data?.is_special) {
          webinars.forEach((w) => (w.is_special = false));
        }
        webinars.push(res.data);
        this.setState({
          addWebinar: false,
          addWebinarLoading: false,
          addWebinarSuccess: true,
          webinars,
        });
      })
      .catch((err) =>
        this.setState({
          addWebinar: false,
          addWebinarLoading: false,
          addWebinarError: err?.response?.data?.message || "Error",
        })
      );
  };

  addAdminGiftSubmit = (values) => {
    this.setState({ addAdminGiftLoading: true });
    admin_api
      .post("/admin-gift", values)
      .then((res) => {
        let gifts = this.state.adminGifts;
        gifts.push(...res.data);
        this.setState({
          addAdminGift: false,
          addAdminGiftLoading: false,
          addAdminGiftSuccess: true,
          adminGifts: gifts,
        });
      })
      .catch((err) =>
        this.setState({
          addAdminGift: false,
          addAdminGiftLoading: false,
          addAdminGiftError: err?.response?.data?.message || "Error",
        })
      );
  };

  editWebinarSubmit = (values) => {
    this.setState({ editWebinarLoading: true });
    admin_api
      .patch(`/webinar/${this.state.editWebinarId}`, values)
      .then((res) => {
        let webinars = this.state.webinars;
        webinars = webinars.map((w) => {
          if (w["id"] === this.state.editWebinarId) {
            return res.data;
          }
          if (res.data?.is_special) {
            return { ...w, is_special: false };
          }
          return w;
        });
        this.setState({
          editWebinar: false,
          editWebinarId: null,
          editWebinarLoading: false,
          editWebinarSuccess: true,
          webinars,
        });
      })
      .catch((err) =>
        this.setState({
          editWebinar: false,
          editWebinarId: null,
          editWebinarLoading: false,
          editWebinarError: err?.response?.data?.message || "Error",
        })
      );
  };

  editAdminGiftSubmit = (values) => {
    this.setState({ editAdminGiftsLoading: true });
    admin_api
      .patch(`/admin-gift/${this.state.editAdminGiftId}`, values)
      .then((res) => {
        let gifts = this.state.adminGifts;
        gifts = gifts.map((g) => {
          if (g["gift_code"] === this.state.editAdminGiftId) {
            return res.data;
          }
          return g;
        });
        this.setState({
          editAdminGift: false,
          editAdminGiftId: null,
          editAdminGiftsLoading: false,
          editAdminGiftSuccess: true,
          adminGifts: gifts,
        });
      })
      .catch((err) =>
        this.setState({
          editAdminGift: false,
          editAdminGiftId: null,
          editAdminGiftsLoading: false,
          editAdminGiftError: err?.response?.data?.message || "Error",
        })
      );
  };

  editUserQuestionSubmit = (values) => {
    this.setState({ editUserQuestionsLoading: true });
    admin_api
      .patch(`/user-questions/${this.state.editUserQuestionId}`, values)
      .then((res) => {
        let questions = this.state.userQuestions;
        questions = questions.map((q) => {
          if (q?.id === this.state.editUserQuestionId) {
            return res.data;
          }
          return q;
        });
        this.setState({
          userQuestions: questions,
          editUserQuestion: false,
          editUserQuestionId: null,
          editUserQuestionsLoading: false,
          editUserQuestionsSuccess: true,
        });
      })
      .catch((err) =>
        this.setState({
          editUserQuestion: false,
          editUserQuestionId: null,
          editUserQuestionsLoading: false,
          editUserQuestionsError: err?.response?.data?.message || "Error",
        })
      );
  };

  render() {
    return (
      <Container fluid={true} className="position-relative">
        <Tabs
          activeKey={this.state.selectedTab}
          onSelect={(k) => this.setSelectedTab(k)}
          className="mb-3"
        >
          <Tab eventKey="gifts" title="Gifts">
            <AdminGift
              gifts={this.state.adminGifts}
              programs={this.state.programs}
              add={this.state.addAdminGift}
              addLoading={this.state.addAdminGiftLoading}
              addSuccess={this.state.addAdminGiftSuccess}
              addError={this.state.addAdminGiftError}
              getLoading={this.state.getAdminGiftLoading}
              getError={this.state.getAdminGiftError}
              getPLoading={this.state.getpLoading}
              getPError={this.state.getpError}
              edit={this.state.editAdminGift}
              editId={this.state.editAdminGiftId}
              editLoading={this.state.editAdminGiftsLoading}
              editSuccess={this.state.editAdminGiftSuccess}
              editError={this.state.editAdminGiftError}
              delete={this.state.deleteAdminGift}
              deleteId={this.state.deleteAdminGiftId}
              deleteLoading={this.state.deleteAdminGiftLoading}
              deleteSuccess={this.state.deleteAdminGiftSuccess}
              deleteError={this.state.deleteAdminGiftError}
              closeAddSuccess={this.closeAddAdminGiftSuccess}
              closeDeleteSuccess={this.closeDeleteAdminGiftSuccess}
              closeEditSuccess={this.closeEditAdminGiftSuccess}
              dismissAddError={this.dismissAddAdminGiftError}
              dismissEditError={this.dismissEditAdminGiftError}
              dismissGetError={this.dismissGetAdminGiftError}
              dismissDeleteError={this.dismissDeleteAdminGiftError}
              dismissGetProgramError={this.dismissGetProgramError}
              handleAdd={this.handleAddAdminGift}
              handleAddClose={this.handleAddAdminGiftClose}
              addSubmit={this.addAdminGiftSubmit}
              handleEdit={this.handleEditAdminGift}
              handleEditClose={this.handleEditAdminGiftClose}
              editSubmit={this.editAdminGiftSubmit}
              handleDelete={this.handleDeleteAdminGiftOpen}
              handleDeleteClose={this.handleDeleteAdminGiftClose}
              deleteSubmit={this.handleDeleteAdminGift}
            />
          </Tab>
          <Tab eventKey="webinars" title="Webinars">
            <WebTemplate
              webinars={this.state.webinars}
              addWebinar={this.state.addWebinar}
              addWebinarLoading={this.state.addWebinarLoading}
              addWebinarSuccess={this.state.addWebinarSuccess}
              addWebinarError={this.state.addWebinarError}
              getWebinarLoading={this.state.getWebinarLoading}
              getWebinarError={this.state.getWebinarError}
              editWebinar={this.state.editWebinar}
              editWebinarId={this.state.editWebinarId}
              editWebinarLoading={this.state.editWebinarLoading}
              editWebinarSuccess={this.state.editWebinarSuccess}
              editWebinarError={this.state.editWebinarError}
              deleteWebinar={this.state.deleteWebinar}
              deleteWebinarId={this.state.deleteWebinarId}
              deleteWebinarLoading={this.state.deleteWebinarLoading}
              deleteWebinarSuccess={this.state.deleteWebinarSuccess}
              deleteWebinarError={this.state.deleteWebinarError}
              closeAddSuccess={this.closeAddSuccess}
              closeDeleteSuccess={this.closeDeleteSuccess}
              closeEditSuccess={this.closeEditSuccess}
              dismissAddError={this.dismissAddError}
              dismissGetError={this.dismissGetError}
              dismissEditError={this.dismissEditError}
              dismissDeleteError={this.dismissDeleteError}
              handleAddWebinar={this.handleAddWebinar}
              handleAddWebinarClose={this.handleAddWebinarClose}
              handleEditWebinar={this.handleEditWebinar}
              handleEditWebinarClose={this.handleEditWebinarClose}
              handleDeleteWebinar={this.handleDeleteWebinar}
              handleDeleteWebinarClose={this.handleDeleteWebinarClose}
              handleDeleteWebinarOpen={this.handleDeleteWebinarOpen}
              addWebinarSubmit={this.addWebinarSubmit}
              editWebinarSubmit={this.editWebinarSubmit}
            />
          </Tab>
          <Tab eventKey="registrations" title="Registrations">
            <WebinarRegistrations
              loading={this.state.getWebregLoading}
              error={this.state.getWebregError}
              dismissGetError={this.dismissGetRegError}
              registrations={this.state.registrations}
            />
          </Tab>
          <Tab eventKey="subscribers" title="Subscribers">
            <EmailSubscribers
              loading={this.state.getSubscribersLoading}
              error={this.state.getSubscribersError}
              dismissGetError={this.dismissSubscribersError}
              subscribers={this.state.subscribers}
            />
          </Tab>
          <Tab eventKey="user-questions" title="User questions">
            <UserQuestions
              formatDate={formatDate}
              questions={this.state.userQuestions}
              getLoading={this.state.getUserQuestionsLoading}
              getError={this.state.getUserQuestionsError}
              edit={this.state.editUserQuestion}
              editId={this.state.editUserQuestionId}
              editLoading={this.state.editUserQuestionsLoading}
              editSuccess={this.state.editUserQuestionsSuccess}
              editError={this.state.editUserQuestionsError}
              closeEditSuccess={this.closeEditUserQuestionSuccess}
              dismissGetError={this.dismissGetUserQuestionsError}
              dismissEditError={this.dismissEditUserQuestionsError}
              handleEdit={this.handleEditUserQuestion}
              handleEditClose={this.handleEditUserQuestionClose}
              editSubmit={this.editUserQuestionSubmit}
            />
          </Tab>
          <Tab eventKey="survey" title="Survey data">
            <Survey
              loading={this.state.getSurveyLoading}
              error={this.state.getSurveyError}
              data={this.state.survey}
              dismissError={this.dismissGetSurveyError}
            />
          </Tab>
          <Tab eventKey="schoolSurvey" title="School feedback">
            <SchoolFeedback
              data={this.state.schoolFeedback}
              loading={this.state.getSchoolFeedbackLoading}
              error={this.state.getSchoolFeedbackError}
              dismissError={this.dismissGetSchoolFeedbackError}
            />
          </Tab>
        </Tabs>
      </Container>
    );
  }
}
